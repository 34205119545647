import React, {
	createContext,
	memo,
	useCallback,
	useContext,
	useRef,
	useState,
} from 'react';
import actions from './actions';
import changeState from '../helpers/changeState';
import socketIOClient from 'socket.io-client';
import { toast } from 'react-toastify';

const initialState = {
	notifications: [],
	usersLogged: [],
	startDateActiveTab: new Date(),
};

export const SocketContext = createContext(initialState);

export const useSocket = () => useContext(SocketContext);

export default (WrappedComponent) => {
	const WithSocket = (props) => {
		const socketRef = useRef();

		socketRef.current = socketIOClient(process.env.REACT_APP_API_BASE_URL);

		socketRef.current.on('lead-assigned', (msgObj) => {
			toast.info(
				<div>
					Lead Assigned
					<br />
					{msgObj.message}
				</div>,
				{
					position: 'top-right',
					autoClose: 4000,
					hideProgressBar: true,
					closeOnClick: true,
					draggable: false,
					pauseOnHover: false,
					onClick: () => {},
				}
			);
		});

		const [context, setContext] = useState({
			...initialState,
			socketRef,
		});

		const value = useCallback(
			() => ({
				context,
				actions: actions(context, changeState(setContext), socketRef.current),
			}),
			[context]
		);

		return (
			<SocketContext.Provider value={value()}>
				<WrappedComponent {...props} />
			</SocketContext.Provider>
		);
	};

	return memo(WithSocket);
};
