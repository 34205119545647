import React, {
	createContext,
	memo,
	useCallback,
	useContext,
	useState,
} from 'react';
import changeState from '../../helpers/changeState';
import actions from '../actions';
import TokenService from '../../../services/TokenService';
import navMenus from '../../_nav';

const buildUserModulePermissions = () => {
	return navMenus
		.filter((navItem) => !!navItem.name)
		.map((navItem) => {
			const submenus = [];
			if (navItem._children?.length) {
				for (const submenu of navItem._children) {
					if (submenu.name) {
						submenus.push({ name: submenu.name, view: false, edit: false });
					}
				}
			}
			return {
				name: navItem.name,
				view: false,
				edit: false,
				submenus,
				specific_definitions: buildSpecificModuleDefinitions(navItem),
			};
		});
};

const buildSpecificModuleDefinitions = (navItem) => {
	const specificDefinitions = [];
	switch (navItem.name) {
		case 'Home': {
			const dashBoardDefinitions = [
				{ name: 'Dashboard - Invoice Notify', enabled: false },
				{ name: 'Dashboard - Quote Notify', enabled: false },
				{ name: 'Dashboard - Follow Ups', enabled: false },
				{ name: 'Dashboard - Leads By Month/Year', enabled: false },
				{ name: 'Dashboard - Leads By Source', enabled: false },
				{ name: 'Dashboard - Receivements', enabled: false },
				{ name: 'Dashboard - Payments', enabled: false },
				{ name: 'Home - Refund Control', enabled: false },
				{ name: 'Dashboard - Ranking', enabled: false },
				{ name: 'Dashboard - Targets and Results', enabled: false },
				{ name: 'Dashboard - Response Time', enabled: false },
				{ name: 'Dashboard - Sellers Last Activy', enabled: false },
				{ name: 'Dashboard - Leads and Sales', enabled: false },
				{
					name: 'Dashboard - Payment Expection vs Confirmed Payments',
					enabled: false,
				},
				{
					name: 'Dashboard - Course Completion and New Programmes',
					enabled: false,
				},
			];
			return [...specificDefinitions, ...dashBoardDefinitions];
		}
		case 'Marketing': {
			const marketingDefinitions = [
				{ name: 'New Student', enabled: false },
				{ name: 'Delegate Student', enabled: false },
				{ name: 'View All Students', enabled: false },
			];
			return [...specificDefinitions, ...marketingDefinitions];
		}
		case 'Finance': {
			const financeDefinitions = [
				{ name: 'View All Timesheets', enabled: false },
				{ name: 'Update All Timesheets', enabled: false },
			];
			return [...specificDefinitions, ...financeDefinitions];
		}
		case 'Sales': {
			const salesDefinitions = [
				{ name: 'View All Forecast', enabled: false },
				{ name: 'Edit All Forecast', enabled: false },
			];
			return [...specificDefinitions, ...salesDefinitions];
		}
		case 'Invoice': {
			const invoiceDefinitions = [
				{ name: 'View All Students', enabled: false },
			];
			return [...specificDefinitions, ...invoiceDefinitions];
		}
		case 'Operations': {
			const quoteDefinitions = [
				{ name: 'Quote - View All Students', enabled: false },
			];
			return [...specificDefinitions, ...quoteDefinitions];
		}
		case 'Communication': {
			const communicationDefinitions = [
				{ name: 'Communication - View All', enabled: false },
			];

			return [...specificDefinitions, ...communicationDefinitions];
		}
		case 'Academic': {
			const academicDefinitions = [
				{ name: 'Application Form - View All Students', enabled: false },
				{ name: 'Attendance - View', enabled: false },
				{ name: 'Attendance - Edit', enabled: false },
				{ name: 'Enrollments - View', enabled: false },
				{ name: 'Enrollments - Edit', enabled: false },
				{ name: 'Holidays - Edit', enabled: false },
				{ name: 'Holidays - View', enabled: false },
				{ name: 'Letters - Edit', enabled: false },
				{ name: 'Letters - View', enabled: false },
			];
			return [...specificDefinitions, ...academicDefinitions];
		}
		default:
			return [];
	}
};

const initialData = {
	search: true,
	userModules: buildUserModulePermissions(),
	user: {},
	notifications: [],
	unseenMessagesData: [],
	unseenMessagesDataHistory: [],
	unseenFollowUps: 0,
	unseenMessages: 0,
	notificationFollowUpClick: null,
};

const getEncodedRoles = () => {
	initialData.user = TokenService.getUserInfo() || {};

	const encodedPermissionsKey = TokenService.encode('permissions');
	const encodedPermissions = localStorage.getItem(encodedPermissionsKey);

	if (encodedPermissions) {
		const decodedPermissions = TokenService.decode(encodedPermissions);
		initialData.user.permissions = JSON.parse(decodedPermissions);
	} else {
		initialData.user.permissions = [];
	}

	const encodedRoleKey = TokenService.encode('roles');
	const encodedRoles = localStorage.getItem(encodedRoleKey);
	if (!encodedRoles) return initialData;

	const decodedRoles = TokenService.decode(encodedRoles);
	if (!decodedRoles) return initialData;

	initialData.user.roles = JSON.parse(decodedRoles);
	return { ...initialData };
};

const initialState = getEncodedRoles();

export const UserContext = createContext(initialState);

export const useUser = () => useContext(UserContext);

export default (WrappedComponent) => {
	const WithUser = (props) => {
		const [context, setContext] = useState(initialState);

		const value = useCallback(
			() => ({
				context,
				actions: actions(context, changeState(setContext)),
				//todo: passar a var "user" para dentro do initialData
				user: TokenService.getUserInfo(),
			}),
			[context]
		);

		return (
			<UserContext.Provider value={value()}>
				<WrappedComponent {...props} />
			</UserContext.Provider>
		);
	};

	return memo(WithUser);
};
