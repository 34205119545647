import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { createBrowserHistory } from 'history';

import { icons } from './assets/icons';

import { Provider } from 'react-redux';
import store from './store';

React.useLayoutEffect = React.useEffect;
React.icons = icons;

const app = document.getElementById('root');
const history = createBrowserHistory();

if (app) {
	// 1. Set up the browser history with the updated location
	// (minus the # sign)
	const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
	if (path) {
		history.replace(path);
	}

	// 2. Render our app
	ReactDOM.render(
		<Provider store={store}>
			<App />
		</Provider>,
		app
	);
}
