import axios from 'axios';
import qs from 'qs';

import TokenService from './TokenService';
import moment from 'moment';

const getToken = () => {
	//todo: check how to handle non token scenarios
	const token = TokenService.getToken();
	if (!token) {
		console.error('Token not found');
	}
	return token;
};

const mountFilterByObj = (obj) => {
	let filterUri = {};
	if (obj) {
		obj.map((formField) => {
			if (
				formField.value !== undefined &&
				formField.value !== null &&
				formField.value !== ''
			) {
				let filterValue;
				if (
					formField.value['$d'] instanceof Date &&
					!isNaN(formField.value['$d'])
				) {
					//verificar comportamento quando houver horario selecionado
					filterValue = moment(formField.value['$d']).format('YYYY-MM-DD');
				} else if (
					typeof formField.value == 'object' &&
					!Array.isArray(formField.value)
				) {
					filterValue = formField.value.value;
				} else if (
					Array.isArray(formField.value) &&
					formField.value?.length > 0 &&
					formField.value.every(
						(field) =>
							field.value !== undefined &&
							field.value !== null &&
							field.value !== ''
					)
				) {
					filterUri[formField.name] = `${formField.criteria}:${formField.value
						.map((field) => field.value)
						.join(',')}`;
				} else {
					filterValue = formField.value;
				}
				if (!filterUri[formField.name]) {
					filterUri[formField.name] = `${formField.criteria}:${filterValue}`;
				} else if (formField.criteria == 'btw') {
					filterUri[formField.name] = `${
						filterUri[formField.name]
					}|${filterValue}`;
				}
			}
		});
	}
	return filterUri;
};

const mountQueryFilter = (obj) => {
	if (!obj) return null;
	const objParams = mountFilterByObj(obj);

	if (objParams) {
		return Object.keys(objParams)
			.map((key) => `&${key}=${objParams[key]}`)
			.join('');
	}

	return null;
};

const mountQueryByCollection = (collection = [], query = false) => {
	const filters = collection
		.map((row) => {
			return {
				[row.name]: row.value,
			};
		})
		.reduce((previousValue, currentValue) => {
			return {
				...currentValue,
				...previousValue,
			};
		}, {});

	if (query) {
		return Object.keys(filters)
			.filter((key) => filters[key])
			.map((key) => `&${key}=${filters[key]}`)
			.join('');
	}

	return filters;
};

const filterOperators = {
	equals: 'eq',
	qte: 'q', //todo: check if this should be 'gte'
	greaterThen: 'gt',
	lessThen: 'lt',
	lessThenEquals: 'lte',
};

const newAxiosInstance = () => {
	const axiosInstance = axios.create({
		baseURL: process.env.REACT_APP_API_BASE_URL,
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
			Authorization: 'Bearer ' + getToken(),
		},
		validateStatus: (status) => true,
	});

	axiosInstance.interceptors.request.use((config) => {
		config.paramsSerializer = (params) =>
			qs.stringify(params, {
				serializeDate: (date) => {
					return moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
				},
			});

		return config;
	});

	axiosInstance.interceptors.response.use((response) => {
		if (response.status === 401) {
			localStorage.clear();

			if (!window.location.href.includes('/login')) {
				window.location.href = '/login';
			}
		}
		handleDates(response.data);
		return response;
	});

	return axiosInstance;
};

const isDate = (value) => {
	const isoDateFormat =
		/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(?:\.\d*)?Z$/;
	return value && typeof value === 'string' && isoDateFormat.test(value);
};

const handleDates = (body) => {
	if (body === null || body === undefined || typeof body !== 'object')
		return body;

	for (const key of Object.keys(body)) {
		const value = body[key];
		if (isDate(value)) {
			body[key] = moment.utc(value).format('YYYY-MM-DD HH:mm:ss');
		} else if (typeof value === 'object') {
			handleDates(value);
		}
	}
};

const isSuccessResponse = (response) =>
	response?.status >= 200 && response?.status < 300;

const API = {
	async get(url, config) {
		const api = newAxiosInstance();
		return await api.get(url, config);
	},
	async post(url, data, config) {
		const api = newAxiosInstance();
		return await api.post(url, data, config);
	},
	async put(url, data, config) {
		const api = newAxiosInstance();
		return await api.put(url, data, config);
	},
	async delete(url, config) {
		const api = newAxiosInstance();
		return await api.delete(url, config);
	},
	mountFilterByObj,
	mountQueryFilter,
	mountQueryByCollection,
	filterOperators,
	isSuccessResponse,
};

export default API;
