import jwt from 'jwt-decode';

export default {
	getToken() {
		const tokenProperty = this.encode('token');
		const encodedToken = localStorage.getItem(tokenProperty);
		if (!encodedToken) {
			console.error('Token not found on TokenService');
			return;
		}
		return this.decode(encodedToken);
	},

	async saveToken(token, ignoreLogged = false) {
		if (!localStorage.getItem('isLoggedIn') || ignoreLogged) {
			const tokenProperty = this.encode('token');
			localStorage.setItem(tokenProperty, this.encode(token));
			return this.encode(token);
		}
	},

	encode(data) {
		return new Buffer(data).toString('base64');
	},

	decode(data) {
		return new Buffer(data, 'base64').toString('ascii');
	},

	getUserInfo() {
		const token = this.getToken();
		if (!token) {
			console.error('Token not found.');
			return;
		}

		const tokenInfo = jwt(token);
		if (!tokenInfo) {
			console.error('Error reading tokenInfo');
			return;
		}
		return tokenInfo;
	},
};
