import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useState,
} from "react";
import changeState from "../../helpers/changeState";
import actions from "../actions";
import moment from "moment";
import { AiFillPhone, AiOutlineMail } from "react-icons/ai";
import { BiWebcam } from "react-icons/bi";
import { FaWhatsapp, FaHandHoldingUsd } from "react-icons/fa";

const initialData = {
  loading: false,
  farolColors: {
    created: "#1C7C54",
    late: "#CF3A6C",
    finished: "#3DA5D9",
    done_late: "#8D99A5",
  },
  iconsFollowUps: {
    Email: <AiOutlineMail color="inherit" />,
    "Phone call": <AiFillPhone color="inherit" />,
    "Video call": <BiWebcam color="inherit" />,
    Whatsapp: <FaWhatsapp color="inherit" />,
    "Quote sent": <FaHandHoldingUsd color="inherit" />,
  },
  followUpList: [],
  selectOptions: {},
  sellers: [],
  showForm: false,
  selectedUser: undefined,
  selectedFollowUp: undefined,
  defaultView: "Month",
  currentDate: moment().format("yyyy-MM-DD"),
  showDeleteModal: false,
};

export const FollowUpContext = createContext(initialData);

export const useFollowUps = () => useContext(FollowUpContext);

export default (WrappedComponent) => {
  const WithFollowUps = (props) => {
    const [context, setContext] = useState(initialData);

    const value = useCallback(
      () => ({ context, actions: actions(context, changeState(setContext)) }),
      [context]
    );

    return (
      <FollowUpContext.Provider value={value()}>
        <WrappedComponent {...props} />
      </FollowUpContext.Provider>
    );
  };

  return memo(WithFollowUps);
};
