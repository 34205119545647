export default [
	{
		_tag: 'CSidebarNavItem',
		name: 'Home',
		to: '/',
		icon: 'cil-home',
		permissions: ['Admin'],
	},
	{
		_tag: 'CSidebarNavItem',
		name: 'My Leads',
		to: '/my-leads',
		icon: 'cilPeople',
	},
	{
		_tag: 'CSidebarNavDropdown',
		name: 'Marketing',
		route: '/marketing',
		icon: 'cil-puzzle',
		_children: [
			{
				_tag: 'CSidebarNavDivider',
				className: 'ml-3',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'ADS Settings',
				icon: {
					name: 'cil-cog',
					className: 'text-info',
				},
				to: '/marketing/ads-settings',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Leads By Campaign',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
				to: '/marketing/leads-by-campaign',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Leads By Source',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
				to: '/marketing/leads-by-source',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Not Attended Leads',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
				to: '/marketing/not-attended-leads',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Response Time per Lead',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
				to: '/marketing/response-time-per-lead',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Delegated Leads',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
				to: '/marketing/reports/delegated-leads',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Lead Sources',
				icon: {
					name: 'cil-globe-alt',
					className: 'text-info',
				},
				to: '/marketing/lead-sources',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Forms',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
				to: '/marketing/forms',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Sales Steps',
				to: '/marketing/sales-steps',
				icon: {
					name: 'cil-list',
					className: 'text-info',
				},
			},
		],
		permissions: ['Admin', 'View Marketing'],
	},
	{
		_tag: 'CSidebarNavItem',
		name: 'Quotes',
		to: '/quotes',
		icon: {
			name: 'cil-notes',
		},
		permissions: ['Admin', 'View Quotes'],
	},
	{
		_tag: 'CSidebarNavItem',
		name: 'Invoice',
		to: '/invoices',
		icon: 'cil-dollar',
		permissions: ['Admin', 'View Invoices'],
	},
	{
		_tag: 'CSidebarNavItem',
		name: 'Communication',
		to: '/communication',
		icon: 'cil-bullhorn',
		permissions: ['Admin'],
	},
	{
		_tag: 'CSidebarNavDropdown',
		name: 'Sales',
		to: '/sales',
		icon: 'cil-chart-pie',
		_children: [
			{
				_tag: 'CSidebarNavDivider',
				className: 'ml-3',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Ranking',
				to: '/sales/ranking',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Sales by Source',
				to: '/sales/by-source',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Sales by Campaign',
				to: '/sales/by-campaign',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Source/Campaign Report',
				to: '/sales/source-campaign-report',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Invoices Accepted',
				to: '/sales/invoices-accepted',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Products Report',
				to: '/sales/products-report',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Sales Forecast',
				to: '/sales/forecast',
				icon: {
					name: 'cil-cog',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Commissions',
				to: '/sales/commissions',
				icon: {
					name: 'cil-cog',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Sales Report',
				to: '/management/sales-report',
				icon: {
					name: 'cil-globe-alt',
					className: 'text-info',
				},
			},
		],
	},
	{
		_tag: 'CSidebarNavDropdown',
		name: 'Logistics',
		to: '/dashboard',
		icon: 'cil-calculator',
		_children: [
			{
				_tag: 'CSidebarNavItem',
				name: 'Before Boarding',
				to: '/logistics/before-boarding',
				icon: {
					name: 'cilAirplaneMode',
					className: 'text-warning',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'After Boarding',
				to: '/logistics/after-boarding',
				icon: {
					name: 'cibPinboard',
					className: 'text-success',
				},
			},
			// {
			// 	_tag: 'CSidebarNavItem',
			// 	name: 'Pipe',
			// 	to: '/logistics/pipe',
			// 	icon: {
			// 		name: 'cibTrello',
			// 		className: 'text-info',
			// 	},
			// },
		],
		permissions: ['Admin', 'View Logistics'],
	},
	{
		_tag: 'CSidebarNavDropdown',
		name: 'Product',
		to: '/product',
		icon: 'cil-basket',
		_children: [
			{
				_tag: 'CSidebarNavItem',
				name: 'Vendors',
				to: '/product/management/vendors',
				icon: {
					name: 'cil-people',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Packages',
				to: '/product/management/packages',
				icon: 'cil-basket',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Products',
				to: '/product/management/products',
				icon: 'cil-basket',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Product Type',
				to: '/product/management/product-type',
				icon: 'cil-tags',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Unit Sell',
				to: '/product/management/unit-sell',
				icon: 'cil-dollar',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Shift',
				to: '/product/management/shift',
				icon: 'cil-tags',
			},
		],
		permissions: ['Admin', 'View Products'],
	},
	{
		_tag: 'CSidebarNavDropdown',
		name: 'Finance',
		to: '/finance',
		icon: 'cil-dollar',
		_children: [
			{
				_tag: 'CSidebarNavItem',
				name: 'Accounts Receivable',
				to: '/finance/accounts-receivable',
				icon: {
					name: 'cil-dollar',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Accounts Received',
				to: '/finance/accounts-received',
				icon: {
					name: 'cil-dollar',
					className: 'text-info',
				},
			},
			// {
			// 	_tag: 'CSidebarNavItem',
			// 	name: 'Accounts Receivable Expected',
			// 	to: '/finance/accounts-receivable-expected',
			// 	icon: {
			// 		name: 'cil-dollar',
			// 		className: 'text-info',
			// 	},
			// },
			{
				_tag: 'CSidebarNavItem',
				name: 'Accounts Payable & Bulks',
				to: '/finance/accounts-payable',
				icon: {
					name: 'cil-dollar',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Paid Accounts',
				to: '/finance/paid-accounts',
				icon: {
					name: 'cil-dollar',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Time Sheet',
				to: '/finance/time-sheet',
				icon: {
					name: 'cil-cog',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Monthly Costs Analysis',
				to: '/finance/monthly-costs-analysis',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Extra Accounts',
				to: '/finance/extra-accounts',
				icon: {
					name: 'cil-dollar',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Cost Centres',
				to: '/finance/cost-centres',
				icon: {
					name: 'cil-globe-alt',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'General Management',
				to: '/finance/general-management',
				icon: {
					name: 'cil-cog',
					className: 'text-info',
				},
			},
		],
		permissions: ['Admin', 'View Finance'],
	},
	{
		_tag: 'CSidebarNavDropdown',
		name: 'Support',
		to: '/support',
		icon: 'cil-comment-square',
		_children: [
			{
				_tag: 'CSidebarNavItem',
				name: 'Refund Control',
				to: '/support/refund-control',
				icon: {
					name: 'cil-dollar',
					className: 'text-info',
				},
			},
		],
		permissions: ['Admin', 'View Support'],
	},
	{
		_tag: 'CSidebarNavDropdown',
		name: 'Accountancy',
		to: '/finance',
		icon: 'cil-globe-alt',
		_children: [
			{
				_tag: 'CSidebarNavItem',
				name: 'Accountancy Report',
				to: '/finance/accountancy-report',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
		],
		permissions: ['Admin', 'View Management'],
	},

	{
		_tag: 'CSidebarNavDropdown',
		name: 'Academic',
		to: '/academic',
		icon: 'cil-school',
		_children: [
			{
				_tag: 'CSidebarNavItem',
				name: 'Course Start Dates',
				to: '/academic/course-start-dates',
				icon: {
					name: 'cil-calendar',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Booking',
				to: '/academic/booking',
				icon: {
					name: 'cil-calendar',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Classes',
				to: '/academic/class-course',
				icon: {
					name: 'cil-school',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Exams',
				to: '/academic/exams',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Report Pre/Booking',
				to: '/academic/report',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Application Form',
				to: '/academic/application-form',
				icon: {
					name: 'cil-newspaper',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Programmes',
				to: '/academic/programmes',
				icon: {
					name: 'cil-list',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Attendance List',
				to: '/academic/attendances',
				icon: {
					name: 'cil-list',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Letter Request',
				to: '/academic/letter-request',
				icon: {
					name: 'cil-list',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Signatures',
				to: '/academic/signatures',
				icon: {
					name: 'cil-fingerprint',
					className: 'text-info',
				},
			},
		],
		permissions: ['Admin'],
	},
	{
		_tag: 'CSidebarNavDropdown',
		name: 'Academic Reports',
		to: '/academic-reports',
		icon: 'cil-copy',
		_children: [
			{
				_tag: 'CSidebarNavItem',
				name: 'Letters Report',
				to: '/academic-reports/letters',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Attendance Progress',
				to: '/academic-reports/attendance-progress',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Class Contingency',
				to: '/academic-reports/class-contingency',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Contingency Map',
				to: '/academic-reports/contingency-map',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Warning Letters',
				to: '/academic-reports/warning-letters',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
		],
		permissions: ['Admin'],
	},
	// {
	// 	_tag: 'CSidebarNavItem',
	// 	name: 'After Sales',
	// 	to: '/after-sales',
	// 	icon: 'cilCheck',
	// 	permissions: ['Admin'],
	// },
	{
		_tag: 'CSidebarNavItem',
		name: 'Pipe After Sales',
		to: '/pipe-after-sales',
		icon: 'cibTrello',
		permissions: ['Admin'],
	},
	{
		_tag: 'CSidebarNavDropdown',
		name: 'Management',
		route: '/management',
		icon: 'cil-puzzle',
		_children: [
			{
				_tag: 'CSidebarNavDivider',
				className: 'ml-3',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Contingency Map',
				to: '/management/contingency-map',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Sales Performance',
				to: '/management/sales-performance',
				icon: {
					name: 'cil-globe-alt',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Sales Report',
				to: '/management/sales-report?graph=1',
				icon: {
					name: 'cil-globe-alt',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Monthly Costs Analysis',
				to: '/finance/monthly-costs-analysis',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Targets and Results',
				to: '/management/targets-and-results',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Leads and Sales',
				to: '/management/sales-leads',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Spaces Available',
				to: '/management/spaces-available',
				icon: {
					name: 'cil-copy',
					className: 'text-info',
				},
			},
		],
		permissions: ['Admin', 'View Management'],
	},
	{
		_tag: 'CSidebarNavDropdown',
		name: 'Settings',
		route: '/general-settings',
		icon: 'cil-cog',
		_children: [
			{
				_tag: 'CSidebarNavDivider',
				className: 'ml-3',
			},

			{
				_tag: 'CSidebarNavItem',
				name: 'Teams',
				icon: {
					name: 'cibAsana',
					className: 'text-white',
				},
				to: '/settings/teams',
			},

			{
				_tag: 'CSidebarNavDivider',
				className: 'm-1',
			},

			{
				_tag: 'CSidebarNavItem',
				name: 'Users',
				icon: {
					name: 'cilGroup',
					className: 'text-warning',
				},
				to: '/general-settings/registrations/users',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Roles',
				to: '/general-settings/registrations/roles',
				icon: {
					name: 'cilFingerprint',
					className: 'text-warning',
				},
			},
			{
				_tag: 'CSidebarNavDivider',
				className: 'm-1',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Terms and Conditions',
				to: '/general-settings/operations/terms-and-conditions',
				icon: {
					name: 'cil-star',
					className: 'text-warning',
				},
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'Documents Templates',
				to: '/general-settings/documents-templates',
				icon: {
					name: 'cib-mail-ru',
					className: 'text-warning',
				},
			},
			{
				_tag: 'CSidebarNavDivider',
				className: 'm-1',
			},
			{
				_tag: 'CSidebarNavItem',
				name: 'General Settings',
				icon: {
					name: 'cil-cog',
					className: 'text-info',
				},
				to: '/settings',
			},
		],
		permissions: ['Admin', 'View Management'],
	},
	{
		_tag: 'CSidebarNavDivider',
		name: '',
		className: 'm-2',
	},
];
