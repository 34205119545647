import React from 'react';
import TokenService from '../../services/TokenService';
import API from '../../services/api';
import { toast } from 'react-toastify';
import * as lodash from 'lodash';

// assets
import notificationSound from '../../assets/notification.mp3';
import { FaBell } from 'react-icons/fa';

const Actions = (state, setData) => ({
	updateUserState: (label, value) => setData({ label, value }),
	setUserData: (userData) => {
		setData({ label: 'user', value: userData });
		const encodedRoleKey = TokenService.encode('roles');
		const encodedRoles = TokenService.encode(JSON.stringify(userData.roles));
		localStorage.setItem(encodedRoleKey, encodedRoles);

		const encodedPermissionsKey = TokenService.encode('permissions');
		const encodedPermissions = TokenService.encode(
			JSON.stringify(userData.permissions)
		);
		localStorage.setItem(encodedPermissionsKey, encodedPermissions);
	},
	setToken: (tokenData) => {
		setData({ label: 'token', value: tokenData });
	},
	filterNavigationByRole: (navigation) => {
		const roles = state.user?.roles;
		return navigation.filter((nav) =>
			roles?.some((role) => nav.permissions?.includes(role.name))
		);
	},
	hasPermission: (permissionName, isSpecific, notIgnoreAdmin = true) => {
		if (
			notIgnoreAdmin &&
			state.user.roles?.some((r) => r.name == 'Admin' || r?.type == 'Admin')
		) {
			return true;
		}

		if (!permissionName?.includes(' ')) {
			return state.user.roles?.some((r) => r.name === permissionName);
		}

		let permission, moduleName, definitionName;
		if (permissionName.includes('My Leads')) {
			const splitResult = permissionName.split(' ');
			permission = splitResult[0];
			moduleName = `My Leads`;
		} else {
			const [pName, mName, ...defName] = permissionName.split(' ');
			permission = pName;
			moduleName = mName;
			definitionName = defName;
		}

		const requestedPermissionName = definitionName?.join(' ');

		let hasPermission = false;

		for (const permissions of state.user.permissions) {
			if (hasPermission) return true;

			const module = permissions.find((p) => p.name === moduleName);
			if (!module) continue;

			if (permission == 'Edit') {
				hasPermission =
					module.submenus?.some(
						(sub) => sub.name === requestedPermissionName && sub.edit
					) || module.edit;
			} else if (permission == 'View') {
				hasPermission =
					module.submenus?.some(
						(sub) => sub.name === requestedPermissionName && sub.view
					) || module.view;
			} else if (isSpecific) {
				let tempPermission = module.specific_definitions.some(
					(s) =>
						s.name ===
							(lodash.isArray(definitionName)
								? definitionName.join(' ')
								: definitionName) && s[permission.toLowerCase()]
				);
				hasPermission = hasPermission || tempPermission;
			}
		}
		return hasPermission;
	},
	hasRole: (roleName) => {
		if (Array.isArray(roleName)) {
			return roleName.some((role) =>
				state.user.roles?.some(
					(r) =>
						r.name === role ||
						r.type === role ||
						r.name == 'Admin' ||
						r?.type == 'Admin'
				)
			);
		}

		return state.user.roles?.some(
			(r) =>
				r.name === roleName ||
				r?.type === roleName ||
				r.name == 'Admin' ||
				r?.type == 'Admin'
		);
	},
	hasRoleNoAdmin: (roleName) => {
		if (Array.isArray(roleName)) {
			return roleName.some((role) =>
				state.user.roles?.some((r) => r.name === role || r.type === role)
			);
		}

		return state.user.roles?.some(
			(r) => r.name === roleName || r?.type === roleName
		);
	},
	loadRoles: async ({
		currentPage,
		resultsPerPage,
		orderField,
		orderDirection,
		useFilters,
		formFields,
		user,
	}) => {
		try {
			setData({ label: 'isLoading', value: true });

			const rolesURI =
				`/roles?_page=${currentPage}` +
				`&_limit=${resultsPerPage.value}` +
				`&_sort_by=${orderField.value}` +
				`&_sort_order=${orderDirection.value}`;

			const filters = useFilters ? API.mountFilterByObj(formFields) : null;

			const resp = await API.get(rolesURI, { params: filters });
			if (API.isSuccessResponse(resp)) {
				setData({
					label: 'roleList',
					value: filterRolesByUser(user, resp.data.data),
				});
				setData({
					label: 'totalRoles',
					value: Number(resp.data.pagination_fields?.total),
				});
				setData({
					label: 'totalPages',
					value: Number(resp.data.pagination_fields?.pages),
				});
			} else {
				console.error('Error loading Roles', resp);
			}
		} catch (e) {
			console.error('Error loading roles', e);
		} finally {
			setData({ label: 'isLoading', value: false });
			setData({ label: 'search', value: false });
		}
	},
	setSearch: (shouldSearch) =>
		setData({ label: 'search', value: shouldSearch }),
	loadRolePermissions: async (roleID, formik) => {
		if (!roleID) {
			console.error('Role ID not found');
			return;
		}

		try {
			setData({ label: 'isLoading', value: true });

			const rolesURI = `/roles/${roleID}/permissions/modules`;

			const resp = await API.get(rolesURI);
			if (API.isSuccessResponse(resp)) {
				const formikData = { id: roleID };
				for (const modulePermission of resp.data) {
					// const updatedSubMenus = [...formik.values[modulePermission.name]?.submenus, ...modulePermission.submenus];
					// formikData[modulePermission.name] = {...modulePermission, ...updatedSubMenus};
					// const previousModulePermissions =
					// 	formik.values[modulePermission.name];
					formikData[modulePermission.name] = { ...modulePermission };
				}

				formik.setValues({ ...formik.values, ...formikData });
			} else {
				console.error('Error loading Roles', resp);
			}
		} catch (e) {
			console.error('Error loading roles', e);
		} finally {
			setData({ label: 'isLoading', value: false });
			setData({ label: 'search', value: false });
		}
	},
	loadRole: async (roleId, validateAccess) => {
		if (!roleId) {
			console.error('Role ID not found');
			return;
		}

		try {
			setData({ label: 'isLoading', value: true });
			const resp = await API.get(`/roles/${roleId}`);
			if (API.isSuccessResponse(resp)) {
				setData({ label: 'roleDetail', value: resp.data });
				validateAccess(resp.data);
			} else {
				console.error('Error loading role', resp);
			}
		} catch (e) {
			console.error('Error Loading role', e);
		} finally {
			setData({ label: 'isLoading', value: false });
			setData({ label: 'search', value: false });
		}
	},
	createNewRole: async (role) => {
		let responseBoolean;

		try {
			const resp = await API.post(`/roles`, role);
			responseBoolean = API.isSuccessResponse(resp);
			if (responseBoolean) {
				toast.success(`Role ${role.name} added`, {
					position: 'bottom-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: false,
					progress: undefined,
				});

				const resp = await API.get('/roles?_page=1', {
					params: {
						_page: 1,
					},
				});

				if (API.isSuccessResponse(resp)) {
					setData({ label: 'roleList', value: resp.data.data });
					setData({
						label: 'totalRoles',
						value: Number(resp.data.pagination_fields?.total),
					});
					setData({
						label: 'totalPages',
						value: Number(resp.data.pagination_fields?.pages),
					});
				} else {
					console.error('Error loading Roles', resp);
				}
			} else {
				toast.error(
					<div>
						Error adding Role <br />
					</div>,
					{
						position: 'bottom-right',
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					}
				);
			}
		} catch (e) {
			toast.error(e.message, {
				position: 'bottom-right',
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
			console.error('Error adding role: ', e.message);
		}

		return responseBoolean;
	},
	updateRolePermission: async (values) => {
		let success = false;
		try {
			setData({ label: 'isLoading', value: true });
			const resp = await API.put(`/roles/${values.id}/modules`, values);
			success = API.isSuccessResponse(resp);
			if (success) {
				toast.success('Permissions Updated', {
					position: 'bottom-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: false,
					progress: undefined,
				});
			} else {
				console.error('Error updating permissions', resp);
			}
		} catch (e) {
			console.error('Error updating permissions', e);
		} finally {
			setData({ label: 'isLoading', value: false });
		}

		return success;
	},
	loadMenus: async (roleIds) => {
		try {
			setData({ label: 'isLoading', value: true });

			const menuList = [];
			for (const roleId of roleIds) {
				const rolesURI = `/roles/${roleId}/permissions/modules`;
				const resp = await API.get(rolesURI);
				if (API.isSuccessResponse(resp)) {
					menuList.push(...resp.data);
				} else {
					console.error('Error loading Roles', resp);
				}
			}
			return menuList;
		} catch (e) {
			console.error('Error loading roles', e);
		}
	},
	broadcastNotifications: async (data) => {
		const alreadyExists =
			JSON.parse(sessionStorage.getItem('unseenMessagesDataHistory')) || [];
		const alreadyExistsIds = Array.isArray(alreadyExists) ? alreadyExists : [];

		const unseenMessagesDataNew = data
			.filter((r) => !alreadyExistsIds.includes(r.id))
			.map((r) => ({ ...r, seen: false }));

		for (const row of unseenMessagesDataNew) {
			if (row.message?.includes('Sold a new course')) {
				await new Promise((resolve) => setTimeout(resolve, 2000));

				toast(
					<div className="d-flex flex-row align-items-center justify-content-start">
						<div className="bell-animate">
							<FaBell
								style={{
									zIndex: 2,
								}}
								size="1em"
								className="text-white mx-3 position-absolute"
							/>
						</div>

						<div className="d-flex flex-column font-weight-bold">
							<span className="h5 mb-0 bell-animate-text">{row.title}</span>
							<span className="text-dark">{row.message}</span>
						</div>
					</div>,
					{
						autoClose: false,
						closeOnClick: true,
						position: 'top-right',
						toastId: row.id,
						draggable: false,
						onClose: async () => {
							await Actions(state, setData).removeMessage(row.id, false);
						},
						onOpen: async () => {
							try {
								const audio = new Audio(notificationSound);
								audio.play();
							} catch (e) {}
						},
					}
				);
			}
		}

		sessionStorage.setItem(
			'unseenMessagesDataHistory',
			JSON.stringify([
				...alreadyExistsIds,
				...unseenMessagesDataNew.map((r) => r.id),
			])
		);
	},
	removeRole: async (id) => {
		try {
			const resp = await API.delete(`/roles/${id}`);
			if (resp.status >= 200 && resp.status < 300) {
				toast.success(`Role deleted`, {
					position: 'bottom-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: false,
					progress: undefined,
				});
				const resp = await API.get('/roles?_page=1', {
					params: {
						_page: 1,
					},
				});
				if (API.isSuccessResponse(resp)) {
					setData({ label: 'roleList', value: resp.data.data });
					setData({
						label: 'totalRoles',
						value: Number(resp.data.pagination_fields?.total),
					});
					setData({
						label: 'totalPages',
						value: Number(resp.data.pagination_fields?.pages),
					});
				} else {
					console.error('Error loading Roles', resp);
				}
			} else {
				toast.error(
					<div>
						{resp.data.message ? (
							<>
								<span className="font-weight-bold">
									{resp.data.message.text}
								</span>

								<div className="d-flex flex-column">
									<span className="font-weight-bol mt-2 mb-2">Users:</span>

									{resp.data.message.users.map((r, index) => (
										<span key={index}>{r}</span>
									))}
								</div>
							</>
						) : (
							<>
								Error deleting Role <br />
							</>
						)}
					</div>,
					{
						position: 'bottom-right',
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					}
				);
			}
		} catch (e) {
			toast.error(e.message, {
				position: 'bottom-right',
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
			console.error('Error deleting role: ', e.message);
		}
	},
	loadNotifications: async () => {
		try {
			const notificationsResponse = await API.get(
				`/users/${state.user.id}/notifications`,
				{
					params: {
						_page: 1,
						_sort_order: 'desc',
						_sort_by: 'id',
					},
				}
			);
			if (API.isSuccessResponse(notificationsResponse)) {
				const { data } = notificationsResponse.data;
				setData({ label: 'notifications', value: data });
				setData({
					label: 'unseenFollowUps',
					value: data?.filter((n) => n.type == 'FOLLOWUP' && !n.seen)?.length,
				});
				setData({
					label: 'unseenMessages',
					value: data?.filter((n) => n.type != 'FOLLOWUP' && !n.seen)?.length,
				});
			}
		} catch (e) {
			console.error('Error loading roles', e);
		}
	},
	clearAllMessages: async (type) => {
		try {
			const response = await API.put(`/users/${state.user.id}/notifications`, {
				seen: true,
				type,
			});
			if (API.isSuccessResponse(response)) {
				await Actions(state, setData).loadNotifications();
			}
		} catch (e) {
			console.error('Error updating notifications', e);
		}
	},
	setObjectData: async (label, value) => {
		setData({ label: label, value: value });
	},
	removeMessage: async (id, showNotification = true) => {
		try {
			const response = await API.delete(`/users/notifications/${id}`);
			if (API.isSuccessResponse(response)) {
				await Actions(state, setData).loadNotifications();

				if (showNotification) {
					toast.success('Notification removed', {
						position: 'bottom-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					return;
				}
			}
		} catch (e) {
			console.error('Error removing notification', e);
		}
	},
	newNotification: async (type, userId, title, message, metadata) => {
		try {
			const response = await API.post(`/users/notifications`, {
				user_id: userId,
				title,
				message,
				type,
				metadata: JSON.stringify(metadata),
			});
			if (API.isSuccessResponse(response)) {
				await Actions(state, setData).loadNotifications();
			}
		} catch (e) {
			console.error('Error creating new notification', e);
		}
	},
});

const filterRolesByUser = (user, roles) => {
	const isAdmin = user.roles.some(
		(r) => r.type === 'Admin' || r.name === 'Admin'
	);
	if (isAdmin) return roles;

	const isSalesManager = user.roles.some((r) => r.type === 'Sales Manager');
	if (isSalesManager) {
		return roles.filter((r) => r.type != 'Admin' && r.type != 'Sales Manager');
	}

	return [];
};

export default Actions;
