import API from './api';
import ReactCountryFlag from 'react-country-flag';
import React from 'react';
import { components } from 'react-select';
import moment from 'moment';
import * as lodash from 'lodash';

import { FaWhatsapp, FaHandHoldingUsd } from 'react-icons/fa';
import { BiWebcam } from 'react-icons/bi';
import { AiOutlineMail, AiFillPhone } from 'react-icons/ai';

const iconsFollowUps = {
	Email: <AiOutlineMail color="inherit" />,
	'Phone call': <AiFillPhone color="inherit" />,
	'Video call': <BiWebcam color="inherit" />,
	Whatsapp: <FaWhatsapp color="inherit" />,
	'Quote sent': <FaHandHoldingUsd color="inherit" />,
};

const fetchOptions = async (uri, params) => {
	let options;
	const resp = await API.get(uri, params);
	if (API.isSuccessResponse(resp)) {
		const { data } = resp.data;

		options = data?.map((t) => {
			if (typeof params?.customReturn == 'function') {
				return params.customReturn(t);
			}
			return {
				label: t.name || t.code,
				value: t.id,
			};
		});
	}
	return options;
};

export default {
	customOptionWithFlags(props) {
		const newProps = {
			...props,
			children: (
				<div>
					{props?.data?.icon ||
						(props?.data?.alpha_2_code && (
							<ReactCountryFlag svg countryCode={props?.data?.alpha_2_code} />
						))}{' '}
					{props?.children}
				</div>
			),
		};
		return (
			<div className="d-flex pl-2 justify-content-center align-items-center">
				<components.Option {...newProps} />
			</div>
		);
	},

	async getAllOptions() {
		const campusList = await this.getCampusList();
		const responsibles = await this.getResponsibles();
		const sellers = await this.getSellers();
		const countries = await this.getCountries();
		const sources = await this.getSources();
		const studentStatus = await this.getStudentStatus();
		const idioms = await this.getIdioms();
		const idiomsLevels = await this.getIdiomsLevels();
		const tags = await this.getTags();
		const allUsersExpectTeachers = await this.getAllExpectTeachers();

		const options = {
			campusList,
			responsibles,
			countries,
			nationalities: countries,
			sources,
			studentStatus,
			idioms,
			idiomsLevels,
			sellers,
			tags,
			allUsersExpectTeachers,
		};

		return options;
	},

	async getStudentOptions() {
		const campaign_name = await this.getCampaignName();
		const campaign_content = await this.getCampaignContent();
		const campaign_medium = await this.getCampaignMedium();
		const campaign_source = await this.getCampaignSource();

		const maritalStatus = await this.getMaritalStatus();

		const campusList = await this.getCampusList();
		const studentStatus = await this.getStudentStatus();

		const responsibles = await this.getAllExpectTeachers();

		const countries = await this.getCountries();
		const sources = await this.getSources();

		const options = {
			campusList,
			responsibles,
			countries,
			nationalities: countries,
			sources,
			studentStatus,

			maritalStatus,

			campaign_name,
			campaign_content,
			campaign_medium,
			campaign_source,
		};

		return options;
	},

	async getSellers() {
		const sellers = await this.getResponsibles({
			active: 'eq:true',
			'user_account_role.role_id': 'eq:3',
		});

		return sellers;
	},

	async getAllExpectTeachers() {
		const users = await this.getResponsibles({
			active: 'eq:true',
			'user_account_role.role_id': 'nin:10,40,12',
		});

		return users;
	},

	async getAllAcademicUsers() {
		const users = await this.getResponsibles({
			active: 'eq:true',
			'user_account_role.role_id': 'in:40,12,1,105,107',
		});

		return users;
	},

	async getTeachers() {
		const sellers = await this.getResponsibles({
			active: 'eq:true',
			'role.name': 'eq:Teacher',
		});

		return sellers;
	},

	async getPossiblesAuthorisedUsers() {
		const sellers = await this.getResponsibles({
			active: 'eq:true',
			_limit: 999,
			'role.name':
				'in:Attendance Support,Letter Support,Academic Support,Admin,Academic Director',
		});

		return [
			...sellers,
			{ value: 14, label: 'Paula Vieira' },
			{ value: 191, label: 'Marta Guinovart' },
			{ value: 52, label: 'Gerard Stuart' },
		];
	},

	async getCampaignName() {
		let cached;
		const resp = await API.get('/marketing/campaign-name', {
			params: {
				_page: 1,
			},
		});
		if (resp.data?.data && API.isSuccessResponse(resp)) {
			cached = resp.data.data.map((row) => ({
				value: row.id,
				label: row.name,
			}));
		} else {
			console.error('Campaign Name list not found.');
		}
		return cached;
	},

	async getCampaignSource() {
		let cached;
		const resp = await API.get('/marketing/campaign-source', {
			params: {
				_page: 1,
			},
		});
		if (resp.data?.data && API.isSuccessResponse(resp)) {
			cached = resp.data.data.map((row) => ({
				value: row.id,
				label: row.name,
			}));
		} else {
			console.error('Campaign Source list not found.');
		}
		return cached;
	},

	async getCampaignContent() {
		let cached;
		const resp = await API.get('/marketing/campaign-content', {
			params: {
				_page: 1,
			},
		});
		if (resp.data?.data && API.isSuccessResponse(resp)) {
			cached = resp.data.data.map((row) => ({
				value: row.id,
				label: row.name,
			}));
		} else {
			console.error('Campaign Content list not found.');
		}
		return cached;
	},

	async getCampaignMedium() {
		let cached;
		const resp = await API.get('/marketing/campaign-medium', {
			params: {
				_page: 1,
				_limit: 9999,
			},
		});
		if (resp.data?.data && API.isSuccessResponse(resp)) {
			cached = resp.data.data.map((row) => ({
				value: row.id,
				label: row.name,
			}));
		} else {
			console.error('Campaign Medium list not found.');
		}
		return cached;
	},

	async getCampaignPlatform() {
		let cached;
		const resp = await API.get('/marketing/campaign-platform', {
			params: {
				_page: 1,
				_limit: 9999,
			},
		});
		if (resp.data?.data && API.isSuccessResponse(resp)) {
			cached = resp.data.data.map((row) => ({
				value: row.id,
				label: row.name,
			}));
		} else {
			console.error('Campaign Platform list not found.');
		}
		return cached;
	},

	async getCampusList() {
		let cachedCampus;
		const resp = await API.get('/branches?_page=1');
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			cachedCampus = resp.data.data.map((campus) => ({
				value: campus.id,
				label: campus.name,
			}));
		} else {
			console.error('Campus list not found.');
		}
		return cachedCampus;
	},

	async getSelectablePropsStudent() {
		let cachedSelectableProps;
		const resp = await API.get('/students/selectable-props');
		if (API.isSuccessResponse(resp)) {
			cachedSelectableProps = resp.data.map((props) => ({
				value: props,
				label: props,
			}));
		} else {
			console.error('Selectable props students not found.');
		}
		return cachedSelectableProps;
	},

	getGeneratedBy() {
		const generated_by = [
			{
				value: 'Agency',
				label: 'Agency',
			},
			{
				value: 'Company',
				label: 'Company',
			},
			{
				value: 'Seller',
				label: 'Seller',
			},
		];

		return generated_by;
	},

	async getResponsibles(filters = {}) {
		const cachedResponsibles = [];
		const resp = await API.get('/users?_page=1&_sort_by=user_account.name', {
			params: filters,
		});
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			cachedResponsibles.push(
				...resp.data.data
					.filter((r) => r.active)
					.map((responsible) => ({
						value: responsible.id,
						label: `${responsible.name} ${responsible.full_name || ''}`,
						alpha_2_code: responsible.nationality.alpha_2_code,
					}))
			);
		} else {
			console.error('Responsible list not found.');
		}

		return cachedResponsibles;
	},

	async getSellersWithCommission(filters = {}) {
		const cachedResponsibles = [];
		const resp = await API.get(
			'/users/commissions?_page=1&_sort_by=user_account.name',
			{
				params: filters,
			}
		);
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			cachedResponsibles.push(
				...resp.data.data
					.filter((r) => r.active)
					.map((responsible) => ({
						value: responsible.id,
						label: `${responsible.name} ${responsible.full_name || ''}`,
					}))
			);
		} else {
			console.error('Sellers list not found.');
		}

		return cachedResponsibles;
	},

	async getManagers() {
		const cachedResponsibles = [];
		const resp = await API.get('/users?_page=1');
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			cachedResponsibles.push(
				...resp.data.data
					.filter((c) =>
						c.roles.some((d) => d.name === 'Sales Manager' && c.active)
					)
					.map((responsible) => ({
						value: responsible.id,
						label: `${responsible.name} ${responsible.full_name || ''}`,
					}))
			);
		} else {
			console.error('Responsible list not found.');
		}

		return cachedResponsibles;
	},

	async getCountries() {
		let cachedCountries;
		const resp = await API.get('/countries?_page=1');
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			cachedCountries = resp.data.data.map((country) => ({
				value: country.id,
				label: country.name,
				icon: <ReactCountryFlag svg countryCode={country.alpha_2_code} />,
			}));
		} else {
			console.error('Countries list not found.');
		}
		return cachedCountries;
	},

	async getNationalities() {
		let cachedNationalities;
		const resp = await API.get('/countries?_page=1');
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			cachedNationalities = resp.data.data.map((country) => ({
				value: country.id,
				label: country.nationality,
				icon: <ReactCountryFlag svg countryCode={country.alpha_2_code} />,
			}));
		} else {
			console.error('Nationalities list not found.');
		}
		return cachedNationalities;
	},

	async getSources() {
		let cachedSources;
		const resp = await API.get('/student-sources?_page=1');
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			cachedSources = resp.data.data.map((src) => ({
				value: src.id,
				label: src.name,
			}));
		} else {
			console.error('Student Sources not found.');
		}
		return cachedSources;
	},

	async getStudentStatus() {
		let cachedStatus;
		const resp = await API.get('/student-status?_page=1');
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			cachedStatus = resp.data.data.map((src) => ({
				value: src.id,
				label: src.name,
			}));
		} else {
			console.error('Sales step not found.');
		}
		return cachedStatus;
	},

	async getIdioms() {
		let cachedIdioms;
		const resp = await API.get('/idioms?_page=1');
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			cachedIdioms = resp.data.data.map((src) => ({
				value: src.id,
				label: src.name,
			}));
		} else {
			console.error('Idioms list not found.');
		}
		return cachedIdioms;
	},

	async getIdiomsLevels() {
		let cachedIdiomsLevel;
		const resp = await API.get('/idiom-levels?_page=1');
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			cachedIdiomsLevel = resp.data.data.map((src) => ({
				value: src.id,
				label: src.name,
			}));
		} else {
			console.error('Idioms list not found.');
		}
		return cachedIdiomsLevel;
	},

	async getTags() {
		let cachedTags;
		const resp = await API.get('/tags?_page=1');
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			cachedTags = resp.data.data.map((src) => ({
				value: src.id,
				label: src.name,
			}));
		} else {
			console.error('Tag list not found.');
		}
		return cachedTags;
	},

	async filterSelectWithFlags(
		inputValue,
		callback,
		apiUrl,
		searchField,
		customValue,
		customLabel,
		paramsSearch = {}
	) {
		if (!inputValue) {
			return callback([]);
		}

		try {
			const resp = await API.get(
				`/${apiUrl}?_page=1&${
					searchField ? searchField : 'name'
				}=lik:${inputValue}`,
				{ params: paramsSearch }
			);
			if (resp.status >= 200 && resp.status < 300 && resp.data?.data) {
				return callback(
					resp.data.data.map((r) => ({
						value: customValue ? r[customValue] : r.id,
						label: customLabel
							? Array.isArray(customLabel)
								? customLabel.map((c) => r[c]).join(' ')
								: r[customLabel]
							: r.name,
						icon: (
							<ReactCountryFlag
								svg
								countryCode={r.alpha_2_code || r?.nationality?.alpha_2_code}
							/>
						),
					}))
				);
			} else {
				console.error('Error fetching select options: ', resp);
			}
		} catch (e) {
			console.error('Error fetching select options: ', e?.message);
		}
	},

	async filterSelect(
		inputValue,
		callback,
		apiUrl,
		searchField,
		customValue,
		customLabel,
		filterIds,
		additionalFields,
		paramsSearch = {}
	) {
		if (!inputValue) {
			return callback([]);
		}

		try {
			const resp = await API.get(
				`/${apiUrl}?_page=1&${
					searchField ? searchField : 'name'
				}=lik:${inputValue}`,
				{ params: paramsSearch }
			);
			if (resp.status >= 200 && resp.status < 300 && resp.data) {
				const returnedList = resp.data?.data || resp.data;

				let resultList = returnedList.map((r) => {
					const mappedObj = {
						value: customValue
							? typeof customValue === 'function'
								? customValue(r)
								: r[customValue]
							: r.id,
						label: customLabel
							? Array.isArray(customLabel)
								? customLabel.map((l) => r[l]).join(' ')
								: typeof customLabel === 'function'
								? customLabel(r)
								: r[customLabel]
							: r.name,
					};

					if (additionalFields?.length) {
						additionalFields.forEach(
							(newField) => (mappedObj[newField] = r[newField])
						);
					}

					return mappedObj;
				});

				if (filterIds?.length) {
					resultList = resultList.filter(
						(obj) => !filterIds.includes(obj.value)
					);
				}

				return callback(resultList);
			} else {
				console.error('Error fetching select options: ', resp);
			}
		} catch (e) {
			console.error('Error fetching select options: ', e?.message);
		}
	},

	async getOptionById(url, property = 'name') {
		let option;
		const resp = await API.get(url);
		if (resp.data && resp.status >= 200 && resp.status < 300) {
			option = { label: resp.data[property], value: resp.data.id };
		} else {
			console.error('User Roles not found.');
		}
		return option;
	},

	async getRoles() {
		let roleList;
		const resp = await API.get('/roles?_page=0');
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			roleList = resp.data.data.map((src) => ({
				value: src.id,
				label: src.name,
			}));
		} else {
			console.error('User Roles not found.');
		}
		return roleList;
	},

	async getBranchSaleTypes(branch_id) {
		if (!branch_id) {
			console.error('getBranchSaleTypes: Branch ID not found');
			return false;
		}
		let salesTypeList;
		const resp = await API.get(`/branches/${branch_id}/sales-types?_page=0`);
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			salesTypeList = resp.data.data.map((src) => ({
				value: src.id,
				label: src.sales_type_name,
			}));
		} else {
			console.error('Sales Type List not found.');
		}
		return salesTypeList;
	},

	async getProductTypes() {
		let productTypeList;
		const resp = await API.get(`/product/products-types?_page=1`);
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			productTypeList = resp.data.data.map((src) => ({
				value: src.id,
				label: src.name,
			}));
		} else {
			console.error('Sales Type List not found.');
		}
		return productTypeList;
	},

	async getProducts(filters = {}) {
		let data;
		const resp = await API.get('/product/products?_page=1', {
			params: filters,
		});
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			data = resp.data.data.map((src) => ({
				value: src.id,
				label: src.name,
				cost: Number(src.cost).toFixed(2),
				price: Number(src.price).toFixed(2),
				product_prices: src.product_prices,
				unit_sell: src.unit_sell,
			}));
		} else {
			console.error('Products List not found.');
		}
		return data;
	},

	async getAcceptanceTerms() {
		let acceptanceTermList;
		const resp = await API.get(
			`/product/acceptance-terms?_page=1&active=eq:true`
		);
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			acceptanceTermList = resp.data.data.map((src) => ({
				value: src.id,
				label: src.name,
				type: src.type,
			}));
		} else {
			console.error('Sales Type List not found.');
		}
		return acceptanceTermList;
	},

	async getUnitSell() {
		let data;
		const resp = await API.get(`/product/units-sell?_page=1`);
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			data = resp.data.data.map((src) => ({ value: src.id, label: src.name }));
		} else {
			console.error('Unit Sell List not found.');
		}
		return data;
	},

	async getPeriodType() {
		let data;
		const resp = await API.get(`/product/periods-types?_page=1`);
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			data = resp.data.data.map((src) => ({ value: src.id, label: src.name }));
		} else {
			console.error('Period Types List not found.');
		}
		return data;
	},

	async getCurrencies() {
		let data;
		const resp = await API.get(`/currencies?_page=1`);
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			data = resp.data.data.map((src) => ({
				value: src.id,
				label: src.name,
				code: src.code,
				alpha_code: src.alpha_code,
				currency_name: src.currency_name,
			}));
		} else {
			console.error('Currency List not found.');
		}
		return data;
	},

	async getLetters() {
		let data;
		const resp = await API.get(`/marketing/email/templates`, {
			params: {
				_page: 1,
				_limit: 100,
				_sort_by: 'name',
				_sort_order: 'asc',
				type: 'eq:letter',
			},
		});
		if (API.isSuccessResponse(resp)) {
			data = resp.data.data.map((t) => ({
				label: t.subject,
				value: t.text,
			}));
		} else {
			console.error('Template list not found.');
		}
		return data;
	},

	async getLettersV2(filters = {}) {
		const response = await API.get(`/marketing/email/v2/templates`, {
			params: {
				_page: 1,
				_limit: 9999,
				type: 'eq:letter',
				...filters,
			},
		});
		if (API.isSuccessResponse(response)) {
			return response.data.map((t) => ({
				label: t.subject,
				value: t.id,
			}));
		} else {
			console.error('Template list not found.');
		}
		return;
	},

	async getLettersV3(filters = {}) {
		const response = await API.get(`/marketing/email/v2/templates`, {
			params: {
				_page: 1,
				_limit: 9999,
				type: 'eq:letter',
				...filters,
			},
		});
		if (API.isSuccessResponse(response)) {
			return response.data.map((t) => ({
				label: t.subject,
				value: t.subject,
			}));
		} else {
			console.error('Letters with name list not found.');
		}
		return;
	},

	async getSignatures() {
		let data;
		const resp = await API.get(`/academic/signatures`, {
			params: {
				_page: 1,
				_limit: 99,
				'signatures.id': 'nin:1,6',
			},
		});
		if (API.isSuccessResponse(resp)) {
			data = resp.data.data.map((t) => ({
				label: t.name,
				value: t.id,
				signature_text: t.content,
			}));
		} else {
			console.error('Signatures list not found.');
		}
		return data;
	},

	async getVendors(filters = {}) {
		let data;
		const resp = await API.get(`/product/vendors`, {
			params: {
				_page: 1,
				...filters,
			},
		});
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			data = resp.data.data.map((src) => ({ value: src.id, label: src.name }));
		} else {
			console.error('Vendor List not found.');
		}
		return data;
	},

	async getVendorsByCostCenter(id) {
		let data;
		const resp = await API.get(`/product/centres/${id}/vendors`);
		if (resp.status >= 200 && resp.status < 300) {
			data = resp.data.map((src) => ({
				value: src.vendor.id,
				label: src.vendor.name,
			}));
		} else {
			console.error('Vendor List not found.');
		}
		return data;
	},

	async getCentreList(filters = {}) {
		let data;
		const resp = await API.get(`/product/centres`, {
			params: {
				_page: 1,
				_limit: 100,
				...filters,
			},
		});

		if (API.isSuccessResponse(resp)) {
			data = resp.data.data.map((src) => ({ value: src.id, label: src.name }));
		} else {
			console.error('Centre List not found.');
		}
		return data;
	},

	async getContractTypes() {
		let data;
		const resp = await API.get(`/product/contract-types?_page=1`);
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			data = resp.data.data.map((c) => ({ label: c.name, value: c.id }));
		} else {
			console.error('Contract Type List not found.');
		}
		return data;
	},

	async getMaritalStatus() {
		let data;
		const resp = await API.get(`/marketing/student-marital-status?_page=0`);
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			data = resp.data.data.map((c) => ({ label: c.name, value: c.id }));
		} else {
			console.error('Marital Status List not found.');
		}
		return data;
	},

	async getStudents(filters) {
		let data;
		const resp = await API.get(
			`/students?_page=0${!lodash.isEmpty(filters) ? filters : ''}`
		);
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			data = resp.data.data.map((std) => ({
				label: `${std.name} ${std.surname || ''}`,
				value: std.id,
				icon: (
					<ReactCountryFlag svg countryCode={std.nationality.alpha_2_code} />
				),
				nationality: std.nationality,
			}));
		} else {
			console.error('Student List not found.');
		}
		return data;
	},

	async getStudentsWithQuotes(filters) {
		let data;
		const resp = await API.get(
			`/students/quotes?_page=0${!lodash.isEmpty(filters) ? filters : ''}`
		);
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			data = resp.data.data.map((std) => ({
				label: `${std.name} ${std.surname || ''}`,
				value: std.id,
				icon: (
					<ReactCountryFlag svg countryCode={std.nationality.alpha_2_code} />
				),
				nationality: std.nationality,
			}));
		} else {
			console.error('Student With Quotes List not found.');
		}
		return data;
	},

	async getStudentsWithBookings(filters) {
		let data;
		const resp = await API.get(
			`/students/bookings?_page=0${!lodash.isEmpty(filters) ? filters : ''}`
		);
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			data = resp.data.data.map((std) => ({
				label: `${std.name} ${std.surname || ''}`,
				value: std.id,
				icon: (
					<ReactCountryFlag svg countryCode={std.nationality.alpha_2_code} />
				),
				nationality: std.nationality,
			}));
		} else {
			console.error('Student With Bookings List not found.');
		}
		return data;
	},

	async getStudentsWithInvoices(filters) {
		let data;
		const resp = await API.get(
			`/students/invoices?_page=0${!lodash.isEmpty(filters) ? filters : ''}`
		);
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			data = resp.data.data.map((std) => ({
				label: `${std.name} ${std.surname || ''}`,
				value: std.id,
				icon: (
					<ReactCountryFlag svg countryCode={std.nationality.alpha_2_code} />
				),
				nationality: std.nationality,
			}));
		} else {
			console.error('Student With Invoices List not found.');
		}
		return data;
	},

	async getStudentsByInvoicesWithEnglishCourse(filters) {
		let data;
		const resp = await API.get(
			`/students/invoices-with-course?_page=0${
				!lodash.isEmpty(filters) ? filters : ''
			}`
		);
		if (resp.data?.data && resp.status >= 200 && resp.status < 300) {
			data = resp.data.data.map((std) => ({
				label: `${std.name} ${std.surname || ''}`,
				value: std.id,
				icon: (
					<ReactCountryFlag svg countryCode={std.nationality.alpha_2_code} />
				),
				nationality: std.nationality,
			}));
		} else {
			console.error('Student By Invoices With English Course List not found.');
		}
		return data;
	},

	async getPaymentConditions() {
		let data;
		const resp = await API.get(`/finance/payment_condition?_page=0`);
		if (resp.data && resp.status >= 200 && resp.status < 300) {
			data = resp.data.map((condition) => ({
				label: condition.name,
				value: condition.id,
				quantity: condition.quantity,
			}));
		} else {
			console.error('Payment Conditions List not found.');
		}
		return data;
	},

	async getPaymentMethods() {
		let data;
		const resp = await API.get(`/finance/payment_type?_page=0`);
		if (resp.data && resp.status >= 200 && resp.status < 300) {
			data = resp.data.map((condition) => ({
				label: condition.name,
				value: condition.id,
			}));
		} else {
			console.error('Payment Method List not found.');
		}
		return data;
	},

	async getCourseStartDates(optionsParams = {}) {
		let data;

		const options = {
			_page: 0,
			_sort_by: 'start_date',
			_limit: 9999,
			...optionsParams,
		};

		const resp = await API.get(`/finance/course_start_dates`, {
			params: options,
		});
		if (resp.data && resp.status >= 200 && resp.status < 300) {
			data = resp.data.map((date) => ({
				label: moment(date.start_date).format('DD/MM/yyyy'),
				value: date.start_date,
				available_am: date.available_am,
				available_pm: date.available_pm,
			}));
		} else {
			console.error('Course Start Date List not found.');
		}
		return data;
	},

	async getInvoiceStatus() {
		let data;
		const resp = await API.get(`/finance/invoice-status?_page=0`);
		if (resp.data && resp.status >= 200 && resp.status < 300) {
			data = resp.data.map((status) => ({
				label: status.name,
				value: status.id,
			}));
		} else {
			console.error('Invoice Status List not found.');
		}
		return data;
	},

	async getFollowUpTypes() {
		let data;
		const resp = await API.get(`/product/followups-types?_page=0`);
		if (resp.data && resp.status >= 200 && resp.status < 300) {
			data = resp.data.data.map((status) => ({
				label: (
					<>
						{iconsFollowUps[status.name]}
						<span style={{ paddingLeft: '5px' }}>{status.name}</span>
					</>
				),
				value: status.id,
			}));
		} else {
			console.error('Follow Up Types List not found.');
		}
		return data;
	},

	async getBankAccounts() {
		let data;
		const resp = await API.get(`/finance/bank-account?_page=0`);
		if (resp.data && resp.status >= 200 && resp.status < 300) {
			data = resp.data.map((account) => ({
				label: account.name,
				value: account.id,
			}));
		} else {
			console.error('Bank Account List not found.');
		}
		return data;
	},

	async getPaymentMethodsWithBankAccount() {
		let data;
		const resp = await API.get(`/finance/payment_type?_page=0`);
		if (resp.data && resp.status >= 200 && resp.status < 300) {
			data = resp.data.map((condition) => ({
				label: condition.name,
				value: {
					id: condition.id,
					bank_account_id: condition.bank_account_id,
				},
			}));
		} else {
			console.error('Payment Method list not found.');
		}
		return data;
	},

	async getTemplatesNoLinkInject(userId) {
		let data;
		const resp = await API.get(
			`/marketing/email/templates?_page=1&_limit=99999&user_id=${userId}`
		);
		if (API.isSuccessResponse(resp)) {
			data = resp.data.data
				.filter(
					(template) =>
						template.invoice_link_inject === false &&
						template.quote_link_inject === false &&
						template.type === 'email'
				)
				.map((t) => ({
					label: t.name,
					value: {
						id: t.id,
						subject: t.subject,
						quote_link_inject: t.quote_link_inject,
						invoice_link_inject: t.invoice_link_inject,
						has_document_template: t.has_document_template,
						body: t.text,
					},
				}));
		} else {
			console.error('Template list not found.');
		}
		return data;
	},

	async getTemplatesAcademic() {
		let data;
		const resp = await API.get(
			`/marketing/email/templates?_page=1&email_template_profile.role_id=in:105,40,12,10,1`
		);
		if (API.isSuccessResponse(resp)) {
			data = resp.data.data.map((t) => ({
				label: t.name,
				value: {
					subject: t.subject,
					body: t.text,
					id: t.id,
				},
			}));
		} else {
			console.error('Template Academic List not found.');
		}
		return data;
	},

	async getTemplates(userId) {
		let data;
		const resp = await API.get(
			`/marketing/email/templates?_page=1&_limit=9999&user_id=${userId}`
		);
		if (API.isSuccessResponse(resp)) {
			data = resp.data.data.map((t) => ({
				label: t.name,
				value: {
					subject: t.subject,
					quote_link_inject: t.quote_link_inject,
					invoice_link_inject: t.invoice_link_inject,
					has_document_template: t.has_document_template,
					body: t.text,
					id: t.id,
				},
			}));
		} else {
			console.error('Template list not found.');
		}
		return data;
	},

	async getClassLevels() {
		return fetchOptions('/academic/class-levels?_page=1', {
			customReturn: (obj) => ({
				label: `${obj.name} (ILEP: ${obj.ilep_code})`,
				value: obj.id,
			}),
		});
	},

	async getClassCourses(params) {
		return fetchOptions(
			'/academic/class-course?_page=1&_sort_order=asc&_sort_by=code&_limit=9999',
			params
		);
	},

	async getBranches(params) {
		return fetchOptions('/branches?_page=1', params);
	},
};
