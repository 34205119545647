import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css';

//core
import 'primereact/resources/primereact.min.css';

import withUserContext, { useUser } from './containers/users/hooks/useUser';
import withSocketContext, { useSocket } from './containers/socket/useSocket';
import withFollowUps from './containers/followups/hooks/useFollowUps';

import { usePageVisibility } from 'react-page-visibility';

import 'react-toastify/dist/ReactToastify.css';
import './scss/style.scss';

import moment from 'moment';

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse" />
	</div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const QuoteDetails = React.lazy(() =>
	import('./views/pages/quote/QuoteDetail')
);
const InvoiceDetails = React.lazy(() =>
	import('./views/pages/invoice/InvoiceDetail')
);
const FormRefund = React.lazy(() =>
	import('./views/pages/form-refund/FormRefund')
);

const ValidateDocumentPage = React.lazy(() =>
	import('./views/pages/validate-document-page/ValidateDocumentPage')
);

const App = () => {
	const isVisible = usePageVisibility();

	const {
		context: { user },
		actions: { broadcastNotifications },
	} = useUser();

	const {
		context: { socketRef },
	} = useSocket();

	// const {
	// 	context: { followUpList },
	// 	actions: { alertIncomingFollowUps },
	// } = useFollowUps();

	useEffect(() => {
		if (user.id && socketRef.current) {
			sessionStorage.removeItem('unseenMessagesDataHistory');

			socketRef.current.emit('notifications', {
				user_id: user.id,
			});

			const saveTimeLogged = setInterval(() => {
				if (user?.id) {
					if (isVisible) {
						socketRef.current.emit('register-time-passed', {
							user_id: user.id,
							register_date: moment().format('YYYY-MM-DD'),
							total_time: 20,
						});

						socketRef.current.emit('notifications', {
							user_id: user.id,
						});

						socketRef.current.on('notifications', (data) => {
							broadcastNotifications(data);
						});
					}
				}
			}, 2e4);

			return () => {
				clearInterval(saveTimeLogged);
			};
		}
	}, [user, socketRef]);

	// useEffect(() => {
	// 	const minuteInterval = setInterval(function () {
	// 		alertIncomingFollowUps();
	// 	}, 1000 * 60);
	// 	return () => clearInterval(minuteInterval);
	// }, [followUpList]);

	return (
		<BrowserRouter>
			<React.Suspense fallback={loading}>
				<Switch>
					<Route
						exact
						path="/login"
						name="Login Page"
						render={(props) => <Login {...props} />}
					/>
					<Route
						exact
						path="/validate"
						name="Document Validate"
						render={(props) => <ValidateDocumentPage {...props} />}
					/>
					<Route
						exact
						path="/validate/:id"
						name="Document Validate"
						render={(props) => <ValidateDocumentPage {...props} />}
					/>
					<Route
						exact
						path="/quote/detail/:id"
						name="Quote Detail"
						render={(props) => <QuoteDetails {...props} />}
					/>
					<Route
						exact
						path="/invoice/detail/:id"
						name="Invoice Detail"
						render={(props) => <InvoiceDetails {...props} />}
					/>
					<Route
						exact
						path="/refund/form/:id"
						name="Form Refund"
						render={(props) => <FormRefund {...props} />}
					/>
					<Route
						path="/"
						name="Home"
						render={(props) => <TheLayout {...props} />}
					/>
				</Switch>

				<ToastContainer
					position="bottom-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					theme="colored"
					pauseOnFocusLoss
					draggable={true}
					limit={4}
					pauseOnHover
				/>
			</React.Suspense>
		</BrowserRouter>
	);
};

export default withSocketContext(withUserContext(withFollowUps(App)));
