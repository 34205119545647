const Actions = (state, setData, socket) => ({
	saveUserConnection: (user) => {
		socket.emit('user-connected', user);
	},
	removeUserConnection: (user) => {
		socket.emit('user-disconnected', user);
	},
	getUsersOnline: async () => {
		socket.emit('server-online-users');
		socket.on('online-users', (response) => {
			setData({ label: 'usersLogged', value: response });
		});
	},
	setObjectData: (label, value) => {
		setData({ label, value });
	},
});

export default Actions;
