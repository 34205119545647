import React from 'react';
import API from '../../services/api';
import { toast } from 'react-toastify';
import SelectOptions from '../../services/SelectOptions';
import moment from 'moment';

const Actions = (state, setData) => ({
	loadFollowUps: async (selectedUser) => {
		setData({ label: 'isLoading', value: true });
		try {
			const followUpResponse = await API.get(`/product/follow-ups`, {
				params: {
					_page: 1,
					_limit: 300,
					user_account_id: `eq:${selectedUser}`,
					_sort_order: 'desc',
					_sort_by: 'id',
				},
			});
			if (API.isSuccessResponse(followUpResponse)) {
				const { data } = followUpResponse.data;
				setData({
					label: 'followUpList',
					value: data.map((f) => {
						const newFollowUp = f;
						newFollowUp.startDate = f.start_time;
						newFollowUp.endDate = f.end_time;
						newFollowUp.notified = false;
						return newFollowUp;
					}),
				});
			} else {
				console.error('Error loading follow ups', followUpResponse);
			}
		} catch (e) {
			console.error('Error loading follow ups', e.message);
		} finally {
			setData({ label: 'isLoading', value: false });
		}
	},
	loadSelectOptions: async (isAdmin, selectedUser) => {
		let studentFilter = '';
		if (!isAdmin) {
			studentFilter = `&student.responsible_id=eq:${selectedUser}`;
		}

		const options = {};
		options.students = await SelectOptions.getStudents(studentFilter);
		options.followUpTypes = await SelectOptions.getFollowUpTypes();

		setData({ label: 'selectOptions', value: options });
	},
	loadSellers: async (currentUser) => {
		const sellerRoleId = 3;
		const response = await API.get(
			`/users?_page=1&_limit=99999&role_id=eq:${sellerRoleId}`
		);
		if (API.isSuccessResponse(response)) {
			const { data } = response.data;
			setData({
				label: 'sellers',
				value: [...data, { name: currentUser.name, id: currentUser.id }],
			});
		} else {
			toast.error('Error when trying to get Sellers', {
				position: 'bottom-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		}
	},
	handleSaveFollowUp: async (followUpData, formik, userId) => {
		const postData = {};
		postData.start_time = followUpData.startDate;
		postData.is_closed = followUpData.is_closed;
		postData.end_time = followUpData.endDate;
		postData.title = followUpData.title;
		postData.observation = followUpData.observation;
		postData.student_id = followUpData.student_id.value;
		postData.followup_type_id = followUpData.followup_type_id.value;
		postData.user_account_id = userId;

		if (followUpData.complete_this_followup_id) {
			postData.complete_this_followup_id =
				followUpData.complete_this_followup_id;
		}

		const isNewFollowUp = !!state.selectedFollowUp.id;

		let followUpResponse;

		let messageTitle = 'Follow Up Created!';

		if (isNewFollowUp) {
			messageTitle = 'Follow Up Updated!';
			followUpResponse = await API.put(
				'/product/follow-ups/' + state.selectedFollowUp.id,
				postData
			);
		} else {
			followUpResponse = await API.post('/product/follow-ups/', postData);
		}
		if (API.isSuccessResponse(followUpResponse)) {
			toast.success(messageTitle, {
				position: 'bottom-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
			setData({ label: 'showForm', value: false });
			setData({ label: 'selectedFollowUp', value: formik.initialValues });
			formik.resetForm();
			await Actions(state, setData).loadFollowUps(state.selectedUser);
		} else {
			toast.error('Error with follow up', {
				position: 'bottom-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			});
		}
	},
	searchCurrentColor: (endDate) => {
		const theEnd = moment(endDate).toDate();
		const theBeginning = moment().toDate();
		const diffMinutes = moment(theEnd).diff(theBeginning, 'm');

		if (diffMinutes >= 0) {
			return state.farolColors['created'];
		}
		return state.farolColors['late'];
	},
	searchColorLateOrOnTime: (endDate, completionDate) => {
		const finishDate = moment(completionDate).toDate();
		const targetDate = moment(endDate).toDate();

		const diffMinutes = moment(finishDate).diff(targetDate, 'm');

		if (diffMinutes >= 0) {
			return state.farolColors['finished'];
		}

		return state.farolColors['done_late'];
	},
	alertIncomingFollowUps: () => {
		const currentDate = moment(new Date());

		for (const followUp of state.followUpList) {
			const followUpIndex = state.followUpList.findIndex((f) => f === followUp);

			const followUpStartTime = moment(followUp.start_time);
			const duration = moment.duration(followUpStartTime.diff(currentDate));
			const minutesToStartFollowUp = duration.asMinutes();

			if (
				!followUp.notified &&
				minutesToStartFollowUp > 0 &&
				minutesToStartFollowUp <= 1
			) {
				toast.info(
					<div className="d-flex flex-column">
						<strong>Your Follow Up is starting soon</strong>
						<p>{followUp.title}</p>
					</div>,
					{
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						draggable: false,
					}
				);
				followUp.notified = true;

				const updatedFollowList = state.followUpList;
				updatedFollowList[followUpIndex] = followUp;
				setData({ label: 'followUpList', value: updatedFollowList });
			} else if (
				!followUp.notified &&
				minutesToStartFollowUp > 0 &&
				minutesToStartFollowUp <= 10
			) {
				toast.info(
					<div className="d-flex flex-column">
						<strong>Your Follow Up starts in less than 10 minutes</strong>
						<p>{followUp.title}</p>
					</div>,
					{
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						draggable: false,
					}
				);
				followUp.notified = true;
				const updatedFollowList = state.followUpList;
				updatedFollowList[followUpIndex] = followUp;
				setData({ label: 'followUpList', value: updatedFollowList });
			}
			//avisar 10 minutos antes
		}
	},
	updateFollowUpState: (label, value) => setData({ label, value }),
});

export default Actions;
